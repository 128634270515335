// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD5OfXaWVYBSbbHtlqapYZVPCS3TNxvMRI",
  authDomain: "brainsprays-read.firebaseapp.com",
  projectId: "brainsprays-read",
  storageBucket: "brainsprays-read.appspot.com",
  messagingSenderId: "4946449338",
  appId: "1:4946449338:web:7249f0999cd988db8c0383",
  measurementId: "G-G0YPW8QSVT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// console.log("FIREBASE APP", app)