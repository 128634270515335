import {
	RootContainer,
	PageHeading,
	BooksContainer,
	BookImage,
	BookImage1,
} from './BookSelection.styles';
import {useNavigate} from 'react-router-dom';

const BookSelection = () => {
	const navigate = useNavigate()

	return (
		<RootContainer>
			<PageHeading>Select a book to read</PageHeading>
			<BooksContainer>
				<BookImage src="/images/book1.png" onClick={() => navigate('/instructions/book1')} />
				<BookImage1 src="/images/book2.jpg" onClick={() => navigate('/instructions/book2')} />
			</BooksContainer>
		</RootContainer>
	)
}

export default BookSelection;