import styled from 'styled-components';

export const RootContainer = styled.div`
	max-width: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const PageHeading = styled.h1`
	text-align: center;
`;

export const BooksContainer = styled.div`
	display: flex;
	width: 70vw;
	padding: 15px;
	justify-content: space-evenly;
`;

export const BookImage = styled.img`
	cursor: pointer;
	width: 200px;
	height: auto;
	box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
`;

export const BookImage1 = styled.img`
	cursor: pointer;
	width: 300px;
	height: 300px;
	box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
`;