import styled from 'styled-components';

export const RootContainer = styled.div`
	min-width: 100vw;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const Header = styled.div`
	min-width: 80%;
	max-width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 30px;
`;

export const HeaderImage = styled.img`
	width: 60px;
	height: auto;
`;

export const MainImage = styled.img`
	height: 250px;
	width: auto;
`;

export const TextContainer = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;
`;

export const SubHeading = styled.span`
	font-size: 18px;
	color: red;
`;

export const OriginalSpan = styled.span`
	font-size: 24px;
	color: black;
`;

export const YourAnswer = styled(OriginalSpan)`
	color: #555;
	cursor: pointer;
`;

export const WordContainer = styled.div`
	display: flex;
	margin: 5px;
	flex-direction: column;
	align-items: center;
`;

export const OriginalSpan1 = styled.span`
	font-size: 64px;
	color: black;
	font-weight: bold;
`;

export const ReadedText = styled(OriginalSpan1)`
	color: green;
	padding-right: 5px;	
`;

export const YourAnswerTitle = styled(ReadedText)`
	color: #555;
`;