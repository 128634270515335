import { useEffect, useState, useMemo } from "react";
import {
  RootContainer,
  Header,
  HeaderImage,
  MainImage,
  TextContainer,
  OriginalSpan,
  OriginalSpan1,
  ReadedText,
  YourAnswerTitle,
  YourAnswer,
  SubHeading,
  WordContainer,
} from './Interview.styles';
import PuffLoader from 'react-spinners/PuffLoader';
import Logo from 'assets/images/brainsprays_logo400.png';
import Shake from 'react-reveal/Shake';
import {useNavigate, useParams} from 'react-router-dom';
import {PrimaryButton, VerticalSpace, HorizontalSpace, Modal} from 'components';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {userActions} from 'modules/user';
import {GrPowerReset} from 'react-icons/gr'
import {animated, useTransition} from 'react-spring';
import useSpeechToText from 'react-hook-speech-to-text';
import ConfettiExplosion from '@reonomy/react-confetti-explosion';
import useRecorder from "utils/useRecorder";

const DATA = {
  book1: [
    {
      text: "I am a bug",
      img: "book1.png",
      occurences: [['I', 'hi', 'eye'], ['am', 'mm', 'm', 'em', 'emm'], ['a', 'ah', 'yeh', 'yeah'], ['bug', 'buck']]
    },
    {
      text: "I am a cat",
      img: "book1_2_cat.png",
      occurences: [['I', 'hi', 'eye'], ['am', 'mm', 'm', 'em', 'emm'], ['a', 'ah', 'yeh', 'yeah'], ['cat', 'cot', 'caught', 'kat']]
    },
    {
      text: "I am a dog",
      img: "book1_3_dog.png",
      occurences: [['I', 'hi', 'eye'], ['am', 'mm', 'm', 'em', 'emm'], ['a', 'ah', 'yeh', 'yeah'], ['dog']]
    },
    {
      text: "I am a hat",
      img: "book1_4_hat.png",
      occurences: [['I', 'hi', 'eye'], ['am', 'mm', 'm', 'em', 'emm'], ['a', 'ah', 'yeh', 'yeah'], ['hut', 'hot', 'had', 'hat']]
    },
    {
      text: "I am a hog",
      img: "book1_5_hog.png",
      occurences: [['I', 'hi', 'eye'], ['am', 'mm', 'm', 'em', 'emm'], ['a', 'ah', 'yeh', 'yeah'], ['hog', 'log', 'hug']]
    },
    {
      text: "I am a log",
      img: "book1_6_log.png",
      occurences: [['I', 'hi', 'eye'], ['am', 'mm', 'm', 'em', 'emm'], ['a', 'ah', 'yeh', 'yeah'], ['log', 'lock', 'luck', 'lack']]
    },
    {
      text: "I am a rat",
      img: "book1_7_rat.png",
      occurences: [['I', 'hi', 'eye'], ['am', 'mm', 'm', 'em', 'emm'], ['a', 'ah', 'yeh', 'yeah'], ['rat']]
    },
    {
      text: "Give me a hug",
      img: "book1_8_hug.png",
      occurences: [['give'], ['me', 'e', 'mee'], ['a', 'ah', 'yeh', 'yeah'], ['hog', 'hug']]
    },
  ],
  book2: [
    {
      text: "I like dogs",
      img: "book2_1_iLike_dog.jpg",
      occurences: [['I', 'hi', 'eye'], ['like', 'likes', 'lyke', 'lyck'], ['dog', 'dogs', 'doge', 'doges']]
    },
    {
      text: "Mom likes dogs",
      img: "book2_2_momLike_dog.jpg",
      occurences: [['mom', 'mum', 'mome', 'mm', 'maugham'], ['like', 'likes', 'lyke', 'lyck'], ['dog', 'dogs', 'doge', 'doges']]
    },
    {
      text: "I like cats",
      img: "book2_3_iLike_cat.jpg",
      occurences: [['I', 'hi', 'eye'], ['like', 'likes', 'lyke', 'lyck'], ['cat', 'cot', 'caught', 'kat']]
    },
    {
      text: "Mom likes cats",
      img: "book2_4_momLike_cat.jpg",
      occurences: [['mom', 'mum', 'mome', 'mm', 'maugham'], ['like', 'likes', 'lyke', 'lyck'], ['cat', 'cot', 'caught', 'kat']]
    },
    {
      text: "I love mom",
      img: "book2_5_iLove_mom.jpg",
      occurences: [['I', 'hi', 'eye'], ['love', 'low', 'lol', 'lofe', 'lof', 'laugh'], ['mom', 'mum', 'mome', 'mm', 'maugham']]
    },
    {
      text: "Mom loves me",
      img: "book2_6_momLove_me.jpg",
      occurences: [['mom', 'mum', 'mome', 'mm', 'maugham'], ['loves', 'lows', 'lols', 'lofes', 'lofs', 'laughs'], ['me', 'mee', 'mi', 'my']]
    },
  ]
}

const Interview = () => {
  const navigate = useNavigate()
  const params = useParams()
  const dispatch = useDispatch()
  const {accountId} = useSelector(state => state.user, shallowEqual)
  const [currentOriginalText, setCurrentOriginalText] = useState(0)
  const [answer, setAnswer] = useState('');
  const [currentWrongAttempt, setCurrentWrongAttempt] = useState(0) // **
  const [currentReadedText, setCurrentReadedText] = useState([]) // **
  const [lastReadedText, setLastReadedText] = useState([]) // **
  const [comparisonPoint, setComparisonPoint] = useState(0);
  const [buttonColor, setButtonColor] = useState('grey');
  const [currentBook, setCurrentBook] = useState(params.book)
  const [showConfetti, setShowConfetti] = useState(false)
  const [replacedText, setReplacedText] = useState(DATA[currentBook][0]?.text)
  let [audioURL, isVoiceRecording, startRecording, stopRecording] = useRecorder();
  const {
      error,
      isRecording,
      interimResult,
      results,
      setResults,
      startSpeechToText,
      stopSpeechToText,
    } = useSpeechToText({
      // continuous: false,
      continuous: true,
      crossBrowser: true,
      googleApiKey: "AIzaSyBgItgnqpBRRFpdZ_tQRQW3D9EvIl0emUE",
      googleCloudRecognitionConfig: {
        languageCode: 'en-US',
        adaption: {
          phraseSets: [
            {
              name: "MomPhrases",
              phrases: ['mom'],
              boost: 100
            }
          ]
        }
      },
      useLegacyResults: false,
      // timeout: 3000
  });

  const compareStrings = (text) => {
    const splittedResultArray = text.split(' ').filter(word => word !== '')
    const splittedQuestionArray = DATA[currentBook][currentOriginalText]?.text.split(' ').filter(word => word !== '')
    let comparisonPointIncrement = 0;
    let comparisonResultArray = []

    if(lastReadedText[lastReadedText.length - 1]?.trim().toLowerCase() === splittedResultArray[0]?.trim().toLowerCase()) {
      splittedResultArray.shift()
    }

    // console.log("H1N1 SPLITTED ARRAYS", splittedResultArray, splittedQuestionArray)
    // console.log("H1N1 RESULT AND QUESTION", text, DATA[currentBook][currentOriginalText]?.text)
    setLastReadedText(splittedResultArray)

    splittedResultArray.forEach((word, i) => {
       // console.log("H1N1 Word And Index", word.toLowerCase().trim(), DATA[currentBook][currentOriginalText]?.occurences[comparisonPoint+i], DATA[currentBook][currentOriginalText]?.occurences[comparisonPoint]?.indexOf(word.toLowerCase().trim()))
      if(word.toLowerCase().trim() === splittedQuestionArray[comparisonPoint+i]?.toLowerCase().trim()) {
        comparisonPointIncrement += 1
        comparisonResultArray = [...comparisonResultArray, true]
      }
      else if(DATA[currentBook][currentOriginalText]?.occurences[comparisonPoint+i]?.indexOf(word.toLowerCase().trim()) !== -1) {
         // console.log("H1N1 Word matched", word, DATA[currentBook][currentOriginalText]?.occurences)
        comparisonPointIncrement += 1
        comparisonResultArray = [...comparisonResultArray, true]
      }
      else {
        comparisonResultArray = [...comparisonResultArray, false]
        comparisonPointIncrement += 1
      }
    })
    setAnswer(p => p+' '+text)
    setButtonColor('orange')
    setCurrentReadedText(p => [...p, ...comparisonResultArray])
    setComparisonPoint(p => p+comparisonPointIncrement)
  }

  useEffect(() => {
    // console.log("H1N1 Comparison Point", comparisonPoint, DATA[currentBook])
    if(comparisonPoint >= DATA[currentBook][currentOriginalText]?.text.split(' ').length) {
      // console.log("H1N1 Right Condition", currentReadedText)
      if(currentReadedText.every(result => result === true)) {
        setButtonColor('green')
      }
      else {
        setButtonColor('black')
      }
    }
  }, [comparisonPoint])

  const findReadIndicatorPosition = () => {
    const wordWidth = replacedText.split(' ').filter(word => word !== '')[0]?.length
    // console.log("Read Indicator Position", wordWidth)
  }

  const nextQuestion = () => {
    const object = {questionNumber: currentOriginalText+1, question: DATA[currentBook][currentOriginalText]?.text || `Question Number ${currentOriginalText+1}`, answer}
    // console.log("H1N1 NEXT QUESTION", object)
    dispatch(userActions.updateAnswerAction.action(object))
    if(currentOriginalText === DATA[currentBook]?.length-1) {
      stopRecording()
      return null;
    }
    setCurrentReadedText([])
    setAnswer('')
    setCurrentOriginalText(p => p+1)
    setComparisonPoint(0)
    setButtonColor('grey')
  }

  // findReadIndicatorPosition()

  useEffect(() => {
    if(results.length) {
      compareStrings(results[results.length-1]?.transcript)
    }
  }, [results])

  useEffect(() => {
    if(!accountId) {
      navigate('/')
    }
    else {
      startSpeechToText()
      startRecording()
    }
  }, [])

  return (
    <RootContainer>
      {showConfetti && <ConfettiExplosion duration={1000} force={0.6} particleCount={50} floorHeight={1600} floorWidth={1600} />}
      <Header>
        <HeaderImage src={Logo} />
        <PrimaryButton onClick={() => navigate('/dashboard')} >Exit To Dashboard</PrimaryButton>
      </Header>
      <VerticalSpace count={3} />
      {
        isRecording
        ?
        <TextContainer>
          <PuffLoader size={30} />
          <HorizontalSpace count={2} />
          <YourAnswer>✅ Listening now (<strong>Speak aloud</strong>) ...</YourAnswer>
        </TextContainer>
        :
        <TextContainer>
          <YourAnswer onClick={() => startSpeechToText()} >❌ Not listening! Click here to listen.</YourAnswer>                            
        </TextContainer>   
      }
      <VerticalSpace count={1} />
      <SubHeading>Note - If not listening, check your browser "Microphone" permissions (<a href="https://shorturl.at/loBCI" target="_blank">Reference article</a>)</SubHeading>      
      <VerticalSpace count={3} />
      <MainImage src={`/images/${DATA[currentBook][currentOriginalText]?.img}`} />
      <VerticalSpace count={1} />
      <TextContainer>
        {
          DATA[currentBook][currentOriginalText]?.text.split(' ').filter(word => word !== '').map((word, i, array) => {
            // console.log("ARRAY", array)
            return (
              <WordContainer>
                <OriginalSpan>{word+" "}</OriginalSpan>
                {i === comparisonPoint && <OriginalSpan>👆</OriginalSpan>}
              </WordContainer>
            )
          })
        }
      </TextContainer>
      <VerticalSpace count={3} />
      <TextContainer>
        <PrimaryButton background={buttonColor} onClick={nextQuestion} >{currentOriginalText >= DATA[currentBook]?.length-1 ? "Submit" : "Next Sentence →" }</PrimaryButton>
      </TextContainer>
    </RootContainer>
  )
}

export default Interview;
