const colors = {
	blue: "#2d7dd2",
	halfWhite: "#E7E7E7",
	green: "#679436",
	red: "#F24236",
	white: "#FFF",
	black: "#000",
	grey: "#333",
	orange: "#FFA500",
}

export default {colors}