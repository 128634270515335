import { useEffect, useState } from "react";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import moment from 'moment';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {userActions} from 'modules/user';
import {toast} from 'react-toastify';

const useRecorder = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {accountId} = useSelector(state => state.user, shallowEqual)
  const [audioURL, setAudioURL] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [recorder, setRecorder] = useState(null);
  const [audioBlob, setAudioBlob] = useState([]);
  const storage = getStorage();

  useEffect(() => {
    // Lazily obtain recorder first time we're recording.
    if (recorder === null) {
      if (isRecording) {
        requestRecorder().then(setRecorder, console.error);
      }
      return;
    }

    // Manage recorder state.
    if (isRecording) {
      recorder.start();
    } else {
      recorder.stop();
    }

    // Obtain the audio when ready.
    const handleData = e => {
      // console.log("HANDLING DATA", e)
      setAudioURL(URL.createObjectURL(e.data));
      const existingData = audioBlob;
      existingData.push(e.data)
      setAudioBlob(existingData);
      toast.success("Saving your session... Please don't refresh or press the back button", {autoClose: false})
      const storageRef = ref(storage, moment().unix().toString());
      let blob = new Blob(existingData, { type: 'video/webm' });
      uploadBytes(storageRef, blob).then((snapshot) => {
        getDownloadURL(storageRef)
          .then(audioUrl => {
             // console.log("DOWNLOAD URL", audioUrl)
             dispatch(userActions.saveAnswerAsyncActions.request({accountId, audioUrl, callback: () => navigate('/thanks')}))
            // navigate('/thanks')
          })
      });
    };

    recorder.addEventListener("dataavailable", handleData);

    recorder.addEventListener('stop', function() {
      // console.log("VIDEO STOPPED", audioBlob)
    });
    return () => recorder.removeEventListener("dataavailable", handleData);
  }, [recorder, isRecording]);

  const startRecording = () => {
    setIsRecording(true);
  };

  const stopRecording = () => {
    setIsRecording(false);
  };

  return [audioURL, isRecording, startRecording, stopRecording];
};

async function requestRecorder() {
  const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  return new MediaRecorder(stream);
}
export default useRecorder;