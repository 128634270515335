import styled from 'styled-components';

export const RootContainer = styled.div`
	width: 100%;
	position: relative;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	overflow: hidden;
`;

export const LogoImage = styled.img`
	height: 100px;
	width: auto;
`;

export const GifImage = styled.img`
	max-width: 100%;
	width:50vh;
	height: auto;
	border-radius: 20px;
`;

export const BodyContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const MainText = styled.h1`
	margin: 0px;
	color: #333;
`;

export const TaglineText = styled.div`
	font-size: 1.5rem;
	line-height: 2.25rem;
	color: #333;
`;