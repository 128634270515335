import {asyncActionGenerator, plainActionGenerator} from 'utils/actionCreator';

export const getUserAsyncActions = asyncActionGenerator('get_user');

export const signupAsyncActions = asyncActionGenerator('create_user');

export const loginAsyncActions = asyncActionGenerator('login');

export const getAllAnswersAsyncActions = asyncActionGenerator('get_all_answers');

export const getDashboardDataAsyncActions = asyncActionGenerator('get_dashoard_data_async_actions');

export const saveLinkedInAnswerAsyncActions = asyncActionGenerator('save_linkedin_answer');

export const updateAnswerAction = plainActionGenerator('update_answer'); 

export const logoutAction = plainActionGenerator('logout'); 

export const saveAnswerAsyncActions = asyncActionGenerator('save_answer');
