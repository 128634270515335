import {
	getUserAsyncActions,
	saveLinkedInAnswerAsyncActions,
	getAllAnswersAsyncActions,
	saveAnswerAsyncActions,
	updateAnswerAction,
	signupAsyncActions,
	loginAsyncActions,
	logoutAction,
} from './actions';

const initialState = {
	isLoading: false,
	answers: [],
	profile: {
		name: '',
		email: ''
	},
	accountId: '',
	allAnswers: [],
	action: '',
	isError: false
}

export default (state = initialState, action) => {
	// console.log("action payload", action)
	switch(action.type) {
		case getUserAsyncActions.actionNames.request:
			return {...state, isLoading: true, accountId: action.payload.data, action: action.payload.action}

		case getUserAsyncActions.actionNames.success:
			return {...state, profile: action.payload, isLoading: false}

		case getUserAsyncActions.actionNames.error:
			return {...state, profile: action.payload, isLoading: false}

		case loginAsyncActions.actionNames.request:
			return {...state, isLoading: true}

		case loginAsyncActions.actionNames.success: {
			return {...state, profile: {email: action.payload.email, name: action.payload.name}, accountId: action.payload.accountId, isLoading: false}
		}

		case loginAsyncActions.actionNames.error:
			return {...state, isLoading: false, isError: true}

		case signupAsyncActions.actionNames.request:
			return {...state, isLoading: true}

		case signupAsyncActions.actionNames.success: {
			// console.log("action payload", action)
			return {...state, profile: {email: action.payload.email, name: action.payload.name}, accountId: action.payload._id, isLoading: false}
		}

		case signupAsyncActions.actionNames.error:
			return {...state, isLoading: false, isError: true}

		case getAllAnswersAsyncActions.actionNames.request:
			return {...state, isLoading: true,}

		case getAllAnswersAsyncActions.actionNames.success:
			return {...state, allAnswers: action.payload, isLoading: false, answers: []}

		case getAllAnswersAsyncActions.actionNames.error:
			return {...state, allAnswers: action.payload, isLoading: false}

		case updateAnswerAction.actionName: {
			// console.log("Update Answer", {...state})
			if(state.answers)
				return {...state, answers: [...state?.answers, action.payload], isLoading: false}
			else
				return {...state, answers: [action.payload], isLoading: false}
		}

		case logoutAction.actionName:
			return {...initialState}

		case saveLinkedInAnswerAsyncActions.actionNames.request:
			return {...state, isLoading: true}

		case saveLinkedInAnswerAsyncActions.actionNames.success:
			return {...state,  isLoading: false}

		case saveLinkedInAnswerAsyncActions.actionNames.error:
			return {...state, profile: action.payload, isLoading: false}

		case saveAnswerAsyncActions.actionNames.request:
			return {...state, isLoading: true}

		case saveAnswerAsyncActions.actionNames.success:
			return {...state, isLoading: false, answers: []}

		case saveAnswerAsyncActions.actionNames.error:
			return {...state, isLoading: false}

		case 'persist/REHYDRATE':
			// console.log("REHYDRATED", action.payload)
			if(action && action.payload && action.payload.user && action.payload.user.profile)
				return {...action?.payload?.user}
			else
				return {...initialState}

		default:
			return {...state}
	}
}